export default {
  pageTitle: "Ngaru Surf School - Costa da Caparica, Portugal",
  header: {
    home: "Home",
    changeLangButton: "🇵🇹 Português",
    cta: "Book now",
    about: "About us",
    lessons: "Lessons",
    gallery: "Gallery",
    contacts: "Contacts",
    logoAlt: "Surf Ngaru",
  },
  hero: {
    title: "Ngaru",
    subtitle: "Surf School",
    description:
      "A professional team awaits you where the pleasure of riding a wave with friends is the main goal",
    cta: "Book your lesson",
    knowMore: "Know more",
  },
  about: {
    title: "About us",
    description: `If you are in the region of Setúbal, we will meet you and we can make the route between our meeting place and the beach, the interior of Serra da Arrábida or the beautiful landscapes of Sado.

When we arrive at Praia da Mata in Caparica, we will do our Surf lesson in the best environment. A professional and motivated team awaits you where the pleasure of riding a wave with friends is the main goal!
`,
    imageAlt: "About us",
  },
  cta: {
    title: "An adventure where smiles and fun are guaranteed",
    cta: "Book your lesson",
  },
  pricing: {
    title: "Lessons",
    description: "Pick one of our packs",
    cards: [
      {
        title: "Group lesson",
        price: 35,
        cta: "Book now",
        items: [
          "Free cancellation up to 24 hours in advance",
          "Technical material included",
          "Certified coaches",
          "Sports insurance included",
          "Duration: 90 minutes",
        ],
      },
      {
        title: "Private lesson",
        price: 80,
        cta: "Book now",
        items: [
          "Free cancellation up to 24 hours in advance",
          "Technical material included",
          "Certified coaches",
          "Sports insurance included",
          "Duration: 90 minutes",
        ],
      },
      {
        title: "Pack 3 lessons",
        price: 100,
        cta: "Book now",
        popular: "Popular",
        items: [
          "Free cancellation up to 24 hours in advance",
          "Technical material included",
          "Certified coaches",
          "Sports insurance included",
          "Duration: 90 minutes",
        ],
      },
      {
        title: "Pack 6 lessons",
        price: 180,
        cta: "Book now",
        items: [
          "Free cancellation up to 24 hours in advance",
          "Technical material included",
          "Certified coaches",
          "Sports insurance included",
          "Duration: 90 minutes",
          "Shareable",
        ],
      },
      {
        title: "Pack 10 lessons",
        price: 250,
        cta: "Book now",
        items: [
          "Free cancellation up to 24 hours in advance",
          "Technical material included",
          "Certified coaches",
          "Sports insurance included",
          "Duration: 90 minutes",
          "Shareable",
        ],
      },
      {
        title: "Pack 12 lessons",
        price: 280,
        cta: "Book now",
        items: [
          "Free cancellation up to 24 hours in advance",
          "Technical material included",
          "Certified coaches",
          "Sports insurance included",
          "Duration: 90 minutes",
          "Shareable",
        ],
      },
    ],
  },
  gallery: {
    title: "Photo gallery",
    description: "Some of our fun surfing moments",
    photoAlt: "Surf photo",
  },
  testimonials: {
    title: "Testimonials",
    description: "What our clients said about us",
  },
  contacts: {
    title: "Book your lesson",
    contacts: "Contacts",
    button: "Send message",
    sending: "Sending...",
    success: `Thanks for your message!
We'll get back to you soon`,
    error: "Couldn't send your message. Please try again.",
    addressLabel: "Address",
    address: "Praia da Mata\nCosta da Caparica, Almada",
    phoneLabel: "Phone",
    phone: "+351 938 609 185",
    email: "info@surfngaru.pt",
    emailLabel: "E-Mail",
    nameLabel: "Name *",
    lessonsLabel: "Lessons",
    messageLabel: "Message *",
    optionDefault: "Pick a pack",
    optionGroup: "Group lesson",
    optionPrivate: "Private lesson",
    optionPack3: "Pack 3 lessons",
    optionPack6: "Pack 6 lessons",
    optionPack10: "Pack 10 lessons",
    optionPack12: "Pack 12 lessons",
    requiredError: "Please fill the required fields",
    errorName: "Please fill your name",
    errorEmail: "Please fill your email",
    errorMessage: "Please fill your message",
  },
  footer: {
    logoAlt: "Surf Ngaru",
    site: "surfngaru.pt",
    facebook: "https://www.facebook.com/EscoladeSurfNgaru/",
    instagram: "https://www.instagram.com/ngarusurfschool/",
  },
  seo: {
    title: "Ngaru Surf School - Costa da Caparica, Portugal",
    description: "Surf School at Praia da Mata - Costa da Caparica, Portugal",
    url: "https://surfngaru.pt/",
    image: "https://surfngaru.pt/logo.png",
  },
};
